import { useState } from "react"
import styled from "styled-components"

import { startOfDay, subDays } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"

import { EmptyState } from "src/components/EmptyState"
import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { extendGuestsData } from "src/components/Homes/Guests/guestsUtil"
import { GuestTable } from "src/components/Homes/Guests/GuestTable"
import { CreateGuestModal } from "src/components/Homes/HomeDetailsV2/Guests/CreateGuestModal"
import { EditGuestModal } from "src/components/Homes/HomeDetailsV2/Guests/EditGuestModal"
import { HomeLoadingState } from "src/components/Homes/HomeDetailsV2/HomeLoadingState"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { Feature } from "src/data/featureAvailability/types/featureAvailabilityTypes"
import {
  SortKey,
  SortOrder,
  useDeleteGuest,
  useFetchGuests,
} from "src/data/guests/queries/guestQueries"
import { IGuestExtended } from "src/data/guests/types/guestTypes"
import { DEFAULT_HOME_NAME } from "src/data/homes/types/homeTypes"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { Breadcrumbs } from "src/ui/Breadcrumbs/Breadcrumbs"
import { AddButton } from "src/ui/Button/AddButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import Svg404 from "src/ui/icons/404.svg"
import { MainView } from "src/ui/Layout/MainView"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function GuestsV2({ homeId }: { homeId: string }) {
  const [guestAction, setGuestAction] = useState<
    | {
        guest: IGuestExtended
        action: "edit" | "remove"
      }
    | {
        action: "add"
      }
  >()
  const [showUpgradeBlocker, setShowUpgradeBlocker] = useState(false)

  const { limit, offset, setOffset } = useUrlPager()
  const { t, langKeys } = useTranslate()
  const guestConnectAvailability = useFeatureAvailability({
    feature: Feature.GUEST_CONNECT,
  })
  const { org } = useOrganization()
  const fetchHome = useFetchHome({
    orgId: org.id,
    homeId,
  })
  const fetchGuests = useFetchGuests(homeId, {
    limit,
    offset,
    checkout_filter: {
      from: subDays(zonedTimeToUtc(startOfDay(new Date()), "Etc/GMT"), 7),
    },
    sort_options: {
      key: SortKey.CHECK_IN,
      order: SortOrder.DESCENDING,
    },
  })
  const fetchIntegrations = useFetchIntegrations({
    orgId: org.id,
    filters: { includeExternalIntegrations: true },
  })
  const deleteGuest = useDeleteGuest({ homeId })

  const home = fetchHome.data
  // Default the integrations since if you are an org member you are not allowed to fetch integrations
  const integrations = fetchIntegrations.data?.integrations || []
  const userCanEditGuests = getAccessLogic({
    role: home?.user_role,
  }).hasAdminAccess

  if (
    fetchHome.isLoading ||
    fetchGuests.isLoading ||
    fetchIntegrations.isLoading
  ) {
    return <HomeLoadingState />
  }

  if (fetchHome.isError || !home || !fetchGuests.data) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  function removeGuest() {
    if (!guestAction || guestAction.action !== "remove") {
      return
    }

    deleteGuest.mutate(guestAction.guest.guest_id, {
      onSettled: () => {
        setGuestAction(undefined)
      },
    })
  }

  return (
    <MainView
      breadcrumbs={
        <Breadcrumbs
          items={[
            { label: t(langKeys.home_plural), to: Routes.Homes.location() },
            {
              label: home.name || DEFAULT_HOME_NAME(home.home_id),
              to: Routes.Home.location(homeId),
            },
            {
              label: t(langKeys.guest_plural),
              to: Routes.HomeGuestsV2.location(homeId),
            },
          ]}
        />
      }
      title={
        <FeatureBlockerText
          hidden={!guestConnectAvailability.ready}
          isFeatureAvailable={guestConnectAvailability.available}
        >
          <MText variant="heading2">{t(langKeys.guest_plural)}</MText>
        </FeatureBlockerText>
      }
      titleBarProps={{
        actionBar: (
          <AddButton
            onClick={() => {
              guestConnectAvailability.available
                ? setGuestAction({ action: "add" })
                : setShowUpgradeBlocker(true)
            }}
            disabled={!userCanEditGuests || !guestConnectAvailability.ready}
          >
            {t(langKeys.add_guest)}
          </AddButton>
        ),
      }}
    >
      <GuestTable
        guests={extendGuestsData(integrations, fetchGuests.data.guests)}
        onEdit={(guest: IGuestExtended) =>
          setGuestAction({ action: "edit", guest })
        }
        onRemove={(guest: IGuestExtended) =>
          setGuestAction({ action: "remove", guest })
        }
        canEdit={userCanEditGuests}
      />
      <PagerWrapper>
        <Pager
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchGuests.data.paging.total_count}
        />
      </PagerWrapper>

      {guestAction?.action === "add" && (
        <CreateGuestModal
          homeId={homeId}
          timezone={home.timezone}
          onClose={() => setGuestAction(undefined)}
        />
      )}

      {guestAction?.action === "edit" && (
        <EditGuestModal
          guest={extendGuestsData(integrations, [guestAction.guest])[0]}
          homeId={homeId}
          timezone={home.timezone}
          onClose={() => setGuestAction(undefined)}
        />
      )}
      {guestAction?.action === "remove" && (
        <ConfirmDialog
          open={true}
          onClose={() => setGuestAction(undefined)}
          title={`Remove '${guestAction.guest.name}'`}
          description={"This guest will be permanently removed."}
          confirmButtonProps={{ color: "destructive" }}
          confirmLabel="Remove"
          onConfirm={() => removeGuest()}
          loading={deleteGuest.isLoading}
        />
      )}

      <UpgradeBlockerDialog
        open={showUpgradeBlocker}
        onClose={() => setShowUpgradeBlocker(false)}
      />
    </MainView>
  )
}

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`
