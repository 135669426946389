import { GuestSMSConfigDialog } from "src/components/Homes/Guests/GuestSmsConfig"
import { usePutGuest } from "src/data/guests/queries/guestQueries"
import { IGuestExtended, IGuestPut } from "src/data/guests/types/guestTypes"

export function EditGuestModal({
  guest,
  homeId,
  timezone,
  onClose,
}: {
  guest: IGuestExtended
  homeId: string
  timezone: string
  onClose: () => void
}) {
  const editNewGuest = usePutGuest()

  function editGuest(data: IGuestPut) {
    if (!guest.guest_id) return

    data.home_id = homeId

    editNewGuest.mutate(
      { guest: data },
      {
        onSuccess: (newGuest) => {
          onClose()
        },
      }
    )
  }

  return (
    <GuestSMSConfigDialog
      open={true}
      homeId={homeId}
      timezone={timezone}
      onEdit={editGuest}
      onCancel={onClose}
      loading={editNewGuest.isLoading}
      guest={guest}
      edit
    />
  )
}
